export interface ICameraFrame {
  encodedImage: string
  height: number
  width: number
  timestamp: number
}

export enum ECameraFrameMode {
  default = 'DEFAULT',
  calibration = 'CALIBRATION',
  trackCalibration = 'TRACK_CALIBRATION',
  anprCalibration = 'ANPR_CALIBRATION',
  adminView = 'ADMIN_VIEW'
}
