export interface IBoxFeatureFlags {
  boxId: string
  featureFlags: { feature: EBoxFeatureFlags; enabled: boolean }[]
}

export enum EBoxFeatureFlags {
  deviceStandby = 'STANDBY',
  bmc = 'BMC',
  datacenter = 'DATACENTER',
  anprCalibration = 'ANPR_CALIBRATION'
}
