import React from 'react'
import { Tooltip } from 'antd'
import { QuestionOutlined } from '@ant-design/icons'

const TooltipColorLegend = ({ colorMap, title }) => {
  return (
    <Tooltip
      overlayStyle={{ whiteSpace: 'pre-line' }}
      title={
        <>
          <h5 className="scc--tooltip--title">{title}</h5>
          <ul>
            {colorMap.map(({ color, label }, index) => (
              <li
                key={index}
                style={{
                  display: 'block',
                  verticalAlign: 'middle',
                  height: '1rem'
                }}
              >
                <span
                  style={{
                    backgroundColor: color,
                    display: 'inline-block',
                    height: '0.8rem',
                    width: '1rem',
                    marginRight: '10px',
                    verticalAlign: 'middle'
                  }}
                ></span>
                <span
                  style={{
                    textAlign: 'center',
                    display: 'inline-block',
                    verticalAlign: 'middle'
                  }}
                >
                  {label}
                </span>
              </li>
            ))}
          </ul>
        </>
      }
      placement={'bottomRight'}
    >
      <QuestionOutlined
        className={'scc--camera-feed--class-color-tooltip-icon'}
      />
    </Tooltip>
  )
}

export default TooltipColorLegend
